import { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { isDesktop, isMobile } from 'react-device-detect';
import { twMerge } from 'tailwind-merge';
import { globalActions } from 'src/store/global';
import Footer from 'src/view/layouts/global/Footer';
import TrendingStores from 'src/view/layouts/global/TrendingStores';
import Sidebar from './Sidebar';
import MarketingPolicyForOldMembers from 'src/view/layouts/global/MarketingPolicyForOldMembers';

interface Props {
  children?: JSX.Element;
  showMobileSidebar?: boolean;
  showFooter?: boolean;
  updateMainWidth: (width: number | string) => void;
}

function GlobalLayout({ showMobileSidebar = true, children, showFooter, updateMainWidth }: Props): JSX.Element {
  useLayoutEffect(() => {
    updateSize();
    setTimeout(() => updateSize(), 500);
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const updateSize = () => {
    const mainElement = document.getElementById('main');
    updateMainWidth(mainElement?.offsetWidth ?? 'auto');
  };

  return (
    <>
      <div className={twMerge('flex', isMobile && showMobileSidebar ? 'mb-[64px]' : '')}>
        <Sidebar
          showOnMobile={showMobileSidebar}
          classNameDesktop="w-[calc(50vw_-_296px)] xl:w-[calc(50vw_-_356px)] min-w-[220px] md:block hidden"
          classNameMobile="block md:hidden"
        />
        <div className={twMerge('min-w-0', 'w-full', isDesktop && 'min-h-screen lg:border-r lg:border-gray-10')}>
          <main id="main" className="min-h-[101vh]">
            {children}
            {showFooter && <Footer />}
          </main>
        </div>
        <TrendingStores className="hidden w-[calc(50vw_-_296px)] min-w-[256px] lg:block xl:w-[calc(50vw_-_356px)]" />
      </div>

      <MarketingPolicyForOldMembers />
    </>
  );
}

const mapDispatchToProps = {
  updateMainWidth: globalActions.updateMainWidth,
};

export default connect(null, mapDispatchToProps)(GlobalLayout);
