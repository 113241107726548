import { isMobile } from 'react-device-detect';
import { IconClose } from 'src/assets/svg';
import BottomSheet from 'src/view/commons/elements/BottomSheet';
import Modal from 'src/view/commons/elements/Modal';

interface IPopUpProps {
  children: JSX.Element;
  isOpen: boolean;
  onClose: () => void;
}

export default function PopUp({ isOpen, onClose, children }: IPopUpProps) {
  return (
    <>
      {isMobile ? (
        <BottomSheet isOpen={isOpen} onClose={onClose}>
          {children}
        </BottomSheet>
      ) : (
        <Modal onClose={onClose} isOpen={isOpen} className="max-w-lg">
          <>
            <div className="my-[8px] flex items-center justify-end px-[24px]">
              <div className="cursor-pointer">
                <IconClose className="flex-shrink-0 stroke-title" onClick={onClose} />
              </div>
            </div>
            {children}
          </>
        </Modal>
      )}
    </>
  );
}
