import { isEmpty } from 'lodash';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { decodeToken } from 'react-jwt';
import dayjs from 'dayjs';
import { googleLogout } from '@react-oauth/google';
import i18n from 'src/locale';
import {
  formatDate,
  LOCALSTORAGE_SNS_DATA,
  LOCAL_STORAGE_HIDDEN_ADVERTISING_DATE,
  LOCAL_STORAGE_FCM_TOKEN,
  mobileAction,
  pathConstants,
  LOCAL_STORAGE_TRAINING_MODE_HIDE_NEW_LABEL,
  LOCALSTORAGE_SETTING_INFO,
  LOCALSTORAGE_GEOLOCATION_USER,
  LOCAL_STORAGE_LEAGUE_HIDE_NEW_LABEL,
  searchParamsContants,
  LOCAL_STORAGE_IGNORE_FORCE_UPDATE_IN_WEBVIEW,
  LOCALSTORAGE_IP_INFO,
  LOCAL_STORAGE_IS_NATIVE_APP,
  LOCAL_STORAGE_WINDOW_RELOAD_DATETIME,
} from 'src/constants/const';
import { RootState } from 'src/store';
import { notificationActions } from 'src/store/notification/action';
import { NotificationType } from 'src/models';
import { history } from 'src/services/history';
import { sendDataToApp } from 'src/hooks/webview';
import { removeSessionId, saveSessionId } from 'src/requests/token';
import { setting } from 'src/requests/api/setting';
import { account, mainAction } from 'src/store/home';
import { loginMiddleware } from 'src/requests/api/account/login';
import { snsType, EnumStatusCode, EnuGender } from 'src/constants/enum';
import { getObjectLocalStorage, resetLocalStorage, saveObjectLocalStorage } from 'src/services/storage';
import { ISignInFormResponse } from 'src/requests/api/account/prop-state.type';
import { storeMapActions } from 'src/store/store-map';
import { courseActions } from 'src/store/course';
import { tournamentActions } from 'src/store/tournament';
import { leagueAction } from 'src/store/league';
import { settingAction } from 'src/store/setting';
import { To } from 'history';
import { trendingStoresActions } from 'src/store/trending-stores';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_WITH_SESSION_ID = 'LOGIN_WITH_SESSION_ID';

export const login = {
  start: () => ({
    type: LOGIN_START,
  }),
  success: (sessionData: ISignInFormResponse, type: snsType | '') => ({
    type: LOGIN_SUCCESS,
    payload: {
      sessionData,
      snsType: type,
    },
  }),
  failed: () => ({
    type: LOGIN_FAILED,
  }),
};

export const loginWithSessionId = (sessionId: string, snsType?: snsType) => ({
  type: LOGIN_WITH_SESSION_ID,
  payload: {
    sessionId,
    snsType,
  },
});

const loginWithIdAndPassword =
  (
    username: string,
    password: string,
    loginPlatform?: string,
    pushToken?: string,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>): Promise<void> => {
    try {
      dispatch(login.start());
      const response = await loginMiddleware.loginWithIdAndPassword(username, password, loginPlatform, pushToken);
      if (response.entities.memberResult === 100) {
        dispatch(handleAfterLogin(response.entities, ''));
      } else {
        dispatch(
          notificationActions.addNotification(i18n.t('error.INVALID_USERNAME_OR_PASSWORD'), NotificationType.DANGER),
        );
        dispatch(login.failed());
      }
    } catch (error: any) {
      console.warn(error);
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
      dispatch(login.failed());
    }
  };

const getGoogleUserInfo = async (dataSNS: any) => {
  // Start get Info User: nicknames, genders, birthdays
  const result = await loginMiddleware.getUserGoogleDetail({
    userId: dataSNS.snsId,
    access_token: dataSNS.accessToken,
  });

  let genderInfo = EnuGender.MALE;
  if (result.genders?.[0]?.value !== 'male') {
    genderInfo = EnuGender.FEMALE;
  }
  let birthdaysGoogle;
  if (result.birthdays?.[0]) {
    birthdaysGoogle = dayjs(
      new Date(
        result.birthdays?.[0]?.date.year,
        result.birthdays?.[0]?.date.month - 1,
        result.birthdays?.[0]?.date.day,
      ),
    ).format(formatDate.NORMAL);
  }
  const infoDataSNS = {
    ...dataSNS,
    ...(result.nicknames?.[0]?.value && { nicknames: result.nicknames?.[0]?.value }),
    ...(result.genders?.[0]?.value && { gender: genderInfo }),
    ...(birthdaysGoogle && { birthdays: birthdaysGoogle }),
  };
  // End get Info User: nicknames, genders, birthdays
  saveObjectLocalStorage(LOCALSTORAGE_SNS_DATA, infoDataSNS);
};

const loginWithGoogle = (googleResponse: any) => async (dispatch: any) => {
  try {
    dispatch(login.start());
    const resInfo = await loginMiddleware.getUserInfoGoogle(googleResponse);
    const dataSNS = {
      snsId: resInfo.sub,
      type: snsType.Google,
      accessToken: googleResponse.access_token,
      email: resInfo.email,
    };
    const response = await loginMiddleware.loginWithSNS(dataSNS);
    if (response.entities.memberResult === EnumStatusCode.SUCCESS) {
      dispatch(handleAfterLogin(response.entities, snsType.Google));
    }
    if (response.entities.memberResult === EnumStatusCode.FAILED) {
      dispatch(login.failed());
      await getGoogleUserInfo(dataSNS);
      history.pushWithSearch(pathConstants.SIGNUP);
    }
    if (response.entities.memberResult === EnumStatusCode.ERROR) {
      dispatch(login.failed());
      saveObjectLocalStorage(LOCALSTORAGE_SNS_DATA, dataSNS);
      history.pushWithSearch(
        {
          pathname: pathConstants.EXISTING_ACCOUNT,
        },
        { existingAccount: response.entities.usrId, mappingInfo: true },
      );
    }
  } catch (error: any) {
    console.warn(error);
    googleLogout();
    notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER);
    dispatch(login.failed());
  }
};

const loginWithFacebook = (facebookResponse: any) => async (dispatch: any) => {
  try {
    dispatch(login.start());
    const dataSNS = {
      accessToken: facebookResponse.accessToken,
      email: facebookResponse.email,
      snsId: facebookResponse.id,
      type: snsType.Facebook,
    };
    const response = await loginMiddleware.loginWithSNS(dataSNS);
    if (response.entities.memberResult === EnumStatusCode.SUCCESS) {
      dispatch(handleAfterLogin(response.entities, snsType.Facebook));
    }

    let genderInfo = EnuGender.MALE;
    if (facebookResponse.gender !== 'male') {
      genderInfo = EnuGender.FEMALE;
    }
    let birthdaysInfo;
    if (facebookResponse.birthday) {
      const dataDate = facebookResponse.birthday.split('/');
      birthdaysInfo = dayjs(new Date(dataDate[2], dataDate[0] - 1, dataDate[1])).format(formatDate.NORMAL);
    }
    if (response.entities.memberResult === EnumStatusCode.FAILED) {
      dispatch(login.failed());
      const infoDataSNS = {
        ...dataSNS,
        ...(facebookResponse.gender && { gender: genderInfo }),
        ...(birthdaysInfo && { birthdays: birthdaysInfo }),
      };
      saveObjectLocalStorage(LOCALSTORAGE_SNS_DATA, infoDataSNS);
      history.pushWithSearch(pathConstants.SIGNUP);
    }
    if (response.entities.memberResult === EnumStatusCode.ERROR) {
      dispatch(login.failed());
      saveObjectLocalStorage(LOCALSTORAGE_SNS_DATA, dataSNS);
      history.pushWithSearch(
        {
          pathname: pathConstants.EXISTING_ACCOUNT,
        },
        { existingAccount: response.entities.usrId, mappingInfo: true },
      );
    }
  } catch (error: any) {
    console.warn(error);
    dispatch(login.failed());
  }
};

const loginWithApple = (appleResponse: any) => async (dispatch: any) => {
  const appleSub: any = decodeToken(appleResponse.id_token);
  try {
    dispatch(login.start());
    const dataSNS = {
      accessToken: appleResponse.id_token,
      email: appleSub.email,
      snsId: appleSub.sub,
      type: snsType.Apple,
    };
    const response = await loginMiddleware.loginWithSNS(dataSNS);
    if (response.entities.memberResult === EnumStatusCode.SUCCESS) {
      dispatch(handleAfterLogin(response.entities, snsType.Apple));
    }
    if (response.entities.memberResult === EnumStatusCode.FAILED) {
      dispatch(login.failed());
      saveObjectLocalStorage(LOCALSTORAGE_SNS_DATA, dataSNS);
      history.pushWithSearch(pathConstants.SIGNUP);
    }
    if (response.entities.memberResult === EnumStatusCode.ERROR) {
      dispatch(login.failed());
      saveObjectLocalStorage(LOCALSTORAGE_SNS_DATA, dataSNS);
      history.pushWithSearch(
        {
          pathname: pathConstants.EXISTING_ACCOUNT,
        },
        { existingAccount: response.entities.usrId, mappingInfo: true },
      );
    }
  } catch (error: any) {
    console.warn(error);
    dispatch(login.failed());
  }
};

const loginWithLINE = (lineInfo: any) => async (dispatch: any) => {
  try {
    dispatch(login.start());
    const dataSNS = {
      type: snsType.Line,
      ...lineInfo,
    };
    const response = await loginMiddleware.loginWithSNS(dataSNS);
    if (response.entities.memberResult === EnumStatusCode.SUCCESS) {
      dispatch(handleAfterLogin(response.entities, snsType.Line));
    }
    if (response.entities.memberResult === EnumStatusCode.FAILED) {
      dispatch(login.failed());
      saveObjectLocalStorage(LOCALSTORAGE_SNS_DATA, dataSNS);
      history.pushWithSearch(pathConstants.SIGNUP);
    }
    if (response.entities.memberResult === EnumStatusCode.ERROR) {
      dispatch(login.failed());
      saveObjectLocalStorage(LOCALSTORAGE_SNS_DATA, dataSNS);
      history.pushWithSearch(
        {
          pathname: pathConstants.EXISTING_ACCOUNT,
        },
        { existingAccount: response.entities.usrId, mappingInfo: true },
      );
    }
  } catch (error: any) {
    console.warn(error);
    notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER);
    dispatch(login.failed());
  }
};

const loginWithWeChat = (code: string) => async (dispatch: any) => {
  try {
    dispatch(login.start());
    const response = await loginMiddleware.loginWithWeChat(code);
    dispatch(handleAfterLogin(response.openid, snsType.Wechat));
  } catch (error: any) {
    console.warn(error);
    dispatch(login.failed());
  }
};

const loginSNSMobile = (data: any) => async (dispatch: any) => {
  try {
    dispatch(login.start());
    const response = data?.signInData;

    const dataSNS = {
      snsId: data?.snsId,
      type: data?.type,
      accessToken: data?.accessToken,
      email: data?.email,
    };

    if (response?.memberResult === EnumStatusCode.SUCCESS) {
      dispatch(handleAfterLogin(response, data?.type));
    }
    if (response?.memberResult === EnumStatusCode.FAILED) {
      dispatch(login.failed());
      if (dataSNS.type === snsType.Google) {
        await getGoogleUserInfo(dataSNS);
      }
      history.pushWithSearch(pathConstants.SIGNUP);
    }
    if (response?.memberResult === EnumStatusCode.ERROR) {
      dispatch(login.failed());
      history.pushWithSearch(
        {
          pathname: pathConstants.EXISTING_ACCOUNT,
        },
        { existingAccount: response.usrId, mappingInfo: true },
      );
    }
  } catch (error: any) {
    console.warn(error);
    dispatch(login.failed());
  }
};

const handleAfterLogin =
  (response: any, type: snsType | '', isSignup = false) =>
  async (dispatch: any) => {
    try {
      saveSessionId(response?.sessionId);
      sendDataToApp({ action: mobileAction.LOGIN_SUCCESS, sessionId: response?.sessionId });
      dispatch(account.resetHomeData());
      dispatch(storeMapActions.resetMap());
      dispatch(courseActions.resetCourseList());
      dispatch(tournamentActions.resetTournamentData());
      dispatch(leagueAction.resetLeagueData());
      dispatch(mainAction.getMyInformation({ isAfterLogin: true }));
      dispatch(trendingStoresActions.reset());

      /* 
      Need to fetch setting info (such as: language,...) after logging in
      If redirect to HOME and SETTING page, don't need to fetch it because it will be fetched in these pages
    */
      const urlSearchParams = new URLSearchParams(history.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const redirectUrl = params[searchParamsContants.REDIRECT_URL];
      if (!!redirectUrl && redirectUrl !== pathConstants.HOME_PAGE && redirectUrl !== pathConstants.SETTING) {
        dispatch(settingAction.settingInformation());
      }

      dispatch(login.success(response, type));

      if (isSignup) {
        const isNativeApp = !!localStorage.getItem(LOCAL_STORAGE_IS_NATIVE_APP);
        isNativeApp
          ? sendDataToApp({ action: mobileAction.HANDLE_AFTER_SIGN_UP })
          : dispatch(notificationActions.addNotification(i18n.t('mess.SETTING_COMPLETE_AFTER_SIGNUP')));
      }
    } catch (error: any) {
      console.warn(error);
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
    }
  };

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export interface ILogoutOptions {
  replace?: boolean;
  redirectPath?: To;
  redirectState?: Object;
  clearedSearchParams?: string[];
}

const logout = (options?: ILogoutOptions) => async (dispatch: any) => {
  const success = () => ({
    type: LOGOUT_SUCCESS,
    payload: {
      replace: options?.replace,
      redirectPath: options?.redirectPath,
      redirectState: options?.redirectState,
      clearedSearchParams: options?.clearedSearchParams,
    },
  });
  const failed = () => ({
    type: LOGOUT_FAILED,
  });
  try {
    const date = getObjectLocalStorage(LOCAL_STORAGE_HIDDEN_ADVERTISING_DATE);
    const tmHideNewLabel = getObjectLocalStorage(LOCAL_STORAGE_TRAINING_MODE_HIDE_NEW_LABEL);
    const leagueHideNewLabel = getObjectLocalStorage(LOCAL_STORAGE_LEAGUE_HIDE_NEW_LABEL);
    const settingInfo = getObjectLocalStorage(LOCALSTORAGE_SETTING_INFO);
    const pushToken = getObjectLocalStorage(LOCAL_STORAGE_FCM_TOKEN);
    const geolocation = getObjectLocalStorage(LOCALSTORAGE_GEOLOCATION_USER);
    const ipInfo = getObjectLocalStorage(LOCALSTORAGE_IP_INFO);
    const windowReloadDatetime = localStorage.getItem(LOCAL_STORAGE_WINDOW_RELOAD_DATETIME);
    const ignoreForceUpdate = !!localStorage.getItem(LOCAL_STORAGE_IGNORE_FORCE_UPDATE_IN_WEBVIEW);
    resetLocalStorage();

    // re-write HIDDEN_ADVERTISING_DATE, LANGUAGE after clear all
    !isEmpty(date) && saveObjectLocalStorage(LOCAL_STORAGE_HIDDEN_ADVERTISING_DATE, date);
    tmHideNewLabel && saveObjectLocalStorage(LOCAL_STORAGE_TRAINING_MODE_HIDE_NEW_LABEL, tmHideNewLabel);
    leagueHideNewLabel && saveObjectLocalStorage(LOCAL_STORAGE_LEAGUE_HIDE_NEW_LABEL, leagueHideNewLabel);
    windowReloadDatetime && localStorage.setItem(LOCAL_STORAGE_WINDOW_RELOAD_DATETIME, windowReloadDatetime);
    !!ignoreForceUpdate && localStorage.setItem(LOCAL_STORAGE_IGNORE_FORCE_UPDATE_IN_WEBVIEW, 'true');
    const newSettingInfo = {
      distUnit: settingInfo.distUnit,
      greenUnit: settingInfo.greenUnit,
      speedUnit: settingInfo.speedUnit,
      onlyWifiPlayYn: settingInfo.onlyWifiPlayYn,
      countryCd: settingInfo.countryCd,
      langCd: settingInfo.langCd,
    };
    !isEmpty(newSettingInfo) && saveObjectLocalStorage(LOCALSTORAGE_SETTING_INFO, newSettingInfo);
    !isEmpty(pushToken) && saveObjectLocalStorage(LOCAL_STORAGE_FCM_TOKEN, pushToken);
    !isEmpty(geolocation) && saveObjectLocalStorage(LOCALSTORAGE_GEOLOCATION_USER, geolocation);
    !isEmpty(ipInfo) && saveObjectLocalStorage(LOCALSTORAGE_IP_INFO, ipInfo);
    await setting.logout({ pushToken });
    // Remove sessionId from localStorage
    removeSessionId();
    dispatch(success());
    sendDataToApp({ action: mobileAction.LOGOUT_SUCCESS });
    googleLogout();
  } catch (error: any) {
    console.warn(error);
    dispatch(failed());
  }
};

export const authActions = {
  loginWithIdAndPassword,
  loginWithGoogle,
  loginWithFacebook,
  loginWithApple,
  loginWithLINE,
  loginWithWeChat,
  loginSNSMobile,
  handleAfterLogin,
  logout,
};
